import React from 'react'
import { Disclosure } from '@headlessui/react'
import { MenuIcon, XIcon, PhoneIcon, MailIcon } from '@heroicons/react/outline'
import { StaticImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'

const header = {
  connect: [
    { name: 'camairacheating@gmail.com', href: 'mailto:camairacheating@gmail.com', icon: MailIcon },
    { name: '(956) 619-1738', href: 'tel:+19566191738', icon: PhoneIcon },
  ],
  explore: [
    { name: 'Residential', href: '/residential' },
    { name: 'Commercial', href: '/commercial' },
    { name: 'Financing', href: '/financing' },
    { name: 'Contact Us', href: '/contact' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/profile.php?id=61552534966899',
      icon: props => (
        <svg fill="currentColor" viewBox="0 0 320 512" {...props}>
          <path
            fill="currentColor"
            d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
          ></path>
        </svg>
      ),
    },
    {
      name: 'Google',
      href: 'https://g.co/kgs/BUzHi1',
      icon: props => (
        <svg fill="currentColor" viewBox="0 0 488 512" {...props}>
          <path
            fill="currentColor"
            d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
          ></path>
        </svg>
      ),
    },
  ],
}

const Header = () => {
  return (
    <Disclosure as="nav" className="py-0 bg-white shadow md:py-1">
      {({ open }) => (
        <>
          <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="flex justify-between">
              <div className="flex justify-between w-full">
                <div className="items-center flex-shrink-0 hidden py-2 md:flex">
                  <Link to="/" className="block w-auto">
                    <StaticImage
                      className="hidden md:flex"
                      src="../images/with-text-inline.svg"
                      width={250}
                      quality={95}
                      formats={['auto', 'webp', 'avif']}
                      alt="cam air ac cooling and heating"
                      placeholder="none"
                    />
                  </Link>
                </div>

                <div className="flex items-center flex-shrink-0 py-2 md:hidden">
                  <Link to="/" className="block w-auto">
                    <StaticImage
                      src="../images/with-text-inline.svg"
                      width={150}
                      quality={95}
                      formats={['auto', 'webp', 'avif']}
                      alt="cam air ac cooling and heating"
                      placeholder="none"
                    />
                  </Link>
                </div>

                {/* Desktop menu */}
                <div className="py-4">
                  <div className="justify-end hidden pb-2 space-x-4 text-gray-600 md:flex">
                    {header.connect.map(item => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="flex items-center space-x-2 text-gray-500 hover:text-gray-900"
                      >
                        <item.icon height={15} />
                        <span>{item.name}</span>
                      </a>
                    ))}
                  </div>

                  <div className="hidden space-x-4 text-lg md:ml-6 md:flex lg:space-x-8">
                    {header.explore.map(item => (
                      <Link key={item.name} to={item.href} className="px-1 pt-2 text-gray-500 hover:text-gray-900">
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>

              <div className="flex items-center -mr-2 md:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  {open ? (
                    <XIcon className="block w-6 h-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block w-6 h-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>
          </div>

          {/* Mobile menu */}
          <Disclosure.Panel className="md:hidden">
            <div className="container flex flex-col px-4 pt-2 pb-3 space-y-8">
              {header.explore.map(item => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="px-1 pt-1 text-xl font-medium text-center text-gray-600"
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="flex flex-col px-4 py-2 space-y-2 sm:px-8">
              {header.connect.map(item => (
                <a
                  key={item.name}
                  href={item.href}
                  className="flex items-center justify-center px-4 py-3 space-x-2 text-xl font-medium text-white border border-transparent rounded-md shadow-sm bg-primary lg:text-2xl sm:px-8"
                >
                  <item.icon height={25} />
                  <span>{item.name}</span>
                </a>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default Header

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { PhoneIcon, MailIcon } from '@heroicons/react/outline'
import { Link } from 'gatsby'

const footer = {
  locationTitle: 'Keeping the Rio Grande Valley Cool',
  locations: [
    'Alamo',
    'Alton',
    'Donna',
    'Edinburg',
    'Edcouch',
    'Elsa',
    'Harlingen',
    'Hidalgo',
    'McAllen',
    'Mercedes',
    'Mission',
    'Palmview',
    'Pharr',
    'San Juan',
    'Weslaco',
  ],
  outro: 'Specializing in preventative maintenance and improving your indoor air quality.',
  connect: [
    { name: 'camairacheating@gmail.com', href: 'mailto:camairacheating@gmail.com', icon: MailIcon },
    { name: '(956) 619-1738', href: 'tel:+19566191738', icon: PhoneIcon },
  ],
  explore: [
    { name: 'Residential', href: '/residential' },
    { name: 'Commercial', href: '/commercial' },
    { name: 'Financing', href: '/financing' },
    { name: 'Contact Us', href: '/contact' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/profile.php?id=61552534966899',
      icon: props => (
        <svg fill="currentColor" viewBox="0 0 320 512" {...props}>
          <path
            fill="currentColor"
            d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"
          ></path>
        </svg>
      ),
    },
    {
      name: 'Google',
      href: 'https://g.co/kgs/BUzHi1',
      icon: props => (
        <svg fill="currentColor" viewBox="0 0 488 512" {...props}>
          <path
            fill="currentColor"
            d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z"
          ></path>
        </svg>
      ),
    },
  ],
}

const Layout = () => {
  return (
    <footer className="bg-white" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>

      <div className="w-full px-4 py-12 text-white lg:py-16 bg-secondary">
        <h3 className="mt-2 mb-12 text-4xl font-semibold tracking-tight text-center md:text-5xl">
          {footer.locationTitle}
        </h3>
        <ul className="grid max-w-4xl grid-cols-2 gap-4 m-auto md:grid-cols-3 lg:grid-cols-3">
          {footer.locations.map(item => (
            <li key={item} className="text-xl text-center">
              {item}
            </li>
          ))}
        </ul>
      </div>

      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <Link to="/" className="block w-auto">
              <StaticImage
                src="../images/with-text-inline.svg"
                width={160}
                quality={95}
                formats={['auto', 'webp', 'avif']}
                alt="cam air ac cooling and heating"
                placeholder="none"
              />
            </Link>
            <p className="text-gray-500 ">{footer.outro}</p>
            <div className="flex space-x-6">
              {footer.social.map(item => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noreferrer"
                  className="text-gray-400 hover:text-gray-500"
                >
                  <item.icon className="w-6 h-6" aria-hidden="true" />
                  <span className="sr-only">{item.name}</span>
                </a>
              ))}
            </div>
          </div>

          <div className="grid grid-cols-1 gap-8 mt-0 md:mt-12 md:grid-cols-2 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-1 md:gap-8">
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">Connect</h3>
                <ul className="mt-4 space-y-4">
                  {footer.connect.map(item => (
                    <li key={item.name}>
                      <a href={item.href} className="flex items-center space-x-2 text-gray-500 hover:text-gray-900">
                        <item.icon height={15} />
                        <span>{item.name}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="md:grid md:grid-cols-1 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold tracking-wider text-gray-400 uppercase">Explore</h3>
                <ul className="mt-4 space-y-4">
                  {footer.explore.map(item => (
                    <li key={item.name}>
                      <Link to={item.href} className="text-gray-500 hover:text-gray-900">
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="pt-8 mt-12 border-t border-gray-200">
          <p className="text-gray-400 xl:text-center">&copy; 2023 Cam-Air. All rights reserved.</p>
        </div>
      </div>
    </footer>
  )
}

export default Layout

import * as React from 'react'
import { Helmet } from 'react-helmet'

import Header from './header'
import Footer from './footer'

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://rsms.me/inter/inter.css" />
      </Helmet>

      <Header />

      <main className="mt-1">{children}</main>
      <Footer />
    </>
  )
}

export default Layout
